export const ADMIN = 'admin';
export const EDITOR = 'editor';
export const SUPER_ADMIN = 'superadmin';
export const OWNER = 'owner';

export const USER_TYPES = {
  [ADMIN]: {
    label: 'Admin',
  },
  [EDITOR]: {
    label: 'Editor',
  },
  [OWNER]: {
    label: 'Owner',
  },
  [SUPER_ADMIN]: {
    label: 'Super Admin',
  },
};

export const ROLE_OPTIONS = [{
  value: EDITOR, label: USER_TYPES[EDITOR].label,
}, {
  value: ADMIN, label: USER_TYPES[ADMIN].label,
}];
