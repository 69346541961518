import client from './client';

export async function fetchPlatforms() {
  const result = await client.get('/platforms', {
    params: {
      perPage: 1000,
    },
  });
  return result.data;
}

export async function fetchPlatform(platformId) {
  if (platformId) {
    const result = await client.get(`/platforms/${platformId}`);
    return result.data;
  }
  return {};
}

export async function createPlatform(data) {
  const formData = new FormData();
  formData.append('file', data.file);

  const result = await client.post('/platforms', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });

  return result.data;
}

export async function changeStatus(platformId, status) {
  const result = await client.put(`/platforms/status/${platformId}`, null, {
    params: {
      status,
    },
  });
  return result.data;
}

export async function createPlatformVersion(platformId, data) {
  const formData = new FormData();
  formData.append('file', data.file);

  const result = await client.put(`/platforms/${platformId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });

  return result.data;
}

export async function deletePlatform(platformId) {
  const result = await client.delete(`/platforms/${platformId}`);
  return result.data;
}

export async function restorePlatform(platformId) {
  const result = await client.put(`/platforms/undelete/${platformId}`);
  return result.data;
}

export async function purgePlatform(platformId, versionId) {
  const result = await client.delete(`/platforms/${platformId}/unused-versions/${versionId}`);
  return result.data;
}
