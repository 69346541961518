import useSystemNotifications from './use-system-notifications';

function SystemNotificationsBreadcrumb({ match }) {
  const { data, isSuccess } = useSystemNotifications();
  if (!isSuccess) {
    return (<span></span>);
  }
  const notification = data.find((n) => n.id === match.params.notificationId);
  return (<span>{notification ? notification.title : ''}</span>);
}

export default SystemNotificationsBreadcrumb;
