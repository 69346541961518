import { useMemo } from 'react';
import dayjs from 'dayjs';

import { SYSTEM_NOTIFICATION_SUPERADMIN } from 'constants/system-notifications';
import useAuth from 'hooks/use-auth';
import useOrganisationDetails from 'features/organisations/use-organisation-details';
import { hashArgs } from 'utils/hash';

const getLicenseNotification = (organisation) => {
  if (!organisation || !organisation.license_end_date) {
    return null;
  }

  const now = dayjs();

  if (organisation.license_end_date < now) {
    return {
      id: hashArgs('license-expired', organisation.id, organisation.license_end_date),
      type: SYSTEM_NOTIFICATION_SUPERADMIN,
      title: 'This organization\'s license has expired',
      message: `License end date: ${organisation.license_end_date}`,
    };
  }

  if (dayjs(organisation.license_end_date).diff(now, 'days') < 7) {
    return {
      id: hashArgs('license-expires-soon', organisation.id, organisation.license_end_date),
      type: SYSTEM_NOTIFICATION_SUPERADMIN,
      title: 'This organization\'s license will expire soon',
      message: `License end date: ${organisation.license_end_date}`,
    };
  }

  return null;
};

function useSuperadminNotifications() {
  const { isSuperAdmin } = useAuth();
  const { data: organisation } = useOrganisationDetails();

  return useMemo(() => {
    if (!isSuperAdmin) {
      return [];
    }

    const notifications = [];

    const licenseNotification = getLicenseNotification(organisation);
    if (licenseNotification) {
      notifications.push(licenseNotification);
    }

    return notifications;
  }, [organisation, isSuperAdmin]);
}

export default useSuperadminNotifications;
