import React from 'react';
import { Routes, Route, Navigate, Outlet, useLocation } from 'react-router-dom';

import AuthLayout from 'layouts/auth-layout';
import AppLayout from 'layouts/app-layout';
import GraphLayout from 'layouts/graph-layout';

import StartPage from 'pages/start';

import RequireAuth from 'features/auth/require-auth';

const CampaignsPage = React.lazy(() => import('pages/campaigns'));
const CampaignDetailsPage = React.lazy(() => import('pages/campaign-details'));
const CampaignEditorPage = React.lazy(() => import('pages/campaign-editor'));

const LoginPage = React.lazy(() => import('pages/login'));
const ForgotPasswordPage = React.lazy(() => import('pages/forgot-password'));
const SetPasswordPage = React.lazy(() => import('pages/set-password'));
const AcceptOwnershipPage = React.lazy(() => import('pages/accept-ownership'));

const OrganisationsPage = React.lazy(() => import('pages/organisations'));
const OrganisationDetailsPage = React.lazy(() => import('pages/organisation-details'));
const UsersPage = React.lazy(() => import('pages/users'));
const UserDetailsPage = React.lazy(() => import('pages/user-details'));
const SpacesPage = React.lazy(() => import('pages/spaces'));
const SpaceDetailsPage = React.lazy(() => import('pages/space-details'));
const TemplatesPage = React.lazy(() => import('pages/templates'));
const TemplateDetailsPage = React.lazy(() => import('pages/template-details'));
const PlatformsPage = React.lazy(() => import('pages/platforms'));
const PlatformDetailsPage = React.lazy(() => import('pages/platform-details'));
const LogsPage = React.lazy(() => import('pages/logs'));

const ContactPage = React.lazy(() => import('pages/contact'));
const ContactStoragePage = React.lazy(() => import('pages/contact-storage'));
const ContactFeaturesPage = React.lazy(() => import('pages/contact-features'));

const StatisticsUsagePage = React.lazy(() => import('pages/statistics-usage'));
const StatisticsStoragePage = React.lazy(() => import('pages/statistics-storage'));
const AccountsPage = React.lazy(() => import('pages/accounts'));
const CallbackOauth = React.lazy(() => import('pages/callback-oauth'));

const SystemNotificationsPage = React.lazy(() => import('pages/system-notifications'));
const SystemNotificationsDetailsPage = React.lazy(() => import('pages/system-notifications-details'));

const SettingsPage = React.lazy(() => import('pages/settings'));

const ExternalPreviewPage = React.lazy(() => import('pages/external-preview'));

const ResumeAuthPage = React.lazy(() => import('pages/resume-auth'));

function AppRoutes() {
  const location = useLocation();

  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path="login" element={
          <React.Suspense fallback={<></>}>
            <LoginPage />
          </React.Suspense>
        }/>
        <Route path="forgot-password" element={
          <React.Suspense fallback={<></>}>
            <ForgotPasswordPage />
          </React.Suspense>
        }/>
        <Route path="set-password/:userId" element={
          <React.Suspense fallback={<></>}>
            <SetPasswordPage />
          </React.Suspense>
        }/>
        <Route path="accept-ownership/:userId" element={
          <React.Suspense fallback={<></>}>
            <AcceptOwnershipPage />
          </React.Suspense>
        }/>
      </Route>
      <Route element={
        <RequireAuth>
          {location.pathname.endsWith('/graph') ? (
            <GraphLayout />
          ) : (
            <AppLayout />
          )}
        </RequireAuth>
      }>
        <Route index element={<StartPage />}/>
        <Route path="campaigns">
          <Route index element={
            <React.Suspense fallback={<></>}>
              <CampaignsPage />
            </React.Suspense>
          } />
          <Route path=":campaignId">
            <Route index element={
              <React.Suspense fallback={<></>}>
                <CampaignDetailsPage />
              </React.Suspense>
            } />
            <Route path="graph" element={
              <React.Suspense fallback={<></>}>
                <CampaignEditorPage />
              </React.Suspense>
            } />
          </Route>
        </Route>
        <Route path="organization" element={
          <RequireAuth admin>
            <React.Suspense fallback={<></>}>
              <OrganisationDetailsPage />
            </React.Suspense>
          </RequireAuth>
        }/>
        <Route path="contact" element={
          <RequireAuth admin>
            <Outlet />
          </RequireAuth>
        }>
          <Route index element={
            <Navigate replace to="/contact/message" />
          } />
          <Route path="message" element={
            <React.Suspense fallback={<></>}>
              <ContactPage />
            </React.Suspense>
          } />
          <Route path="storage" element={
            <React.Suspense fallback={<></>}>
              <ContactStoragePage />
            </React.Suspense>
          } />
          <Route path="features" element={
            <React.Suspense fallback={<></>}>
              <ContactFeaturesPage />
            </React.Suspense>
          } />
        </Route>
        <Route path="profile" element={
          <React.Suspense fallback={<></>}>
            <UserDetailsPage />
          </React.Suspense>
        }/>
        <Route path="logs" element={
          <React.Suspense fallback={<></>}>
            <LogsPage />
          </React.Suspense>
        }/>
        <Route path="organizations" element={
          <RequireAuth superAdmin>
            <React.Suspense fallback={<></>}>
              <OrganisationsPage />
            </React.Suspense>
          </RequireAuth>
        }>
        </Route>
        <Route path="users" element={
          <RequireAuth admin>
            <Outlet />
          </RequireAuth>
        }>
          <Route index element={
            <React.Suspense fallback={<></>}>
              <UsersPage />
            </React.Suspense>
          } />
          <Route path=":userId" element={
            <React.Suspense fallback={<></>}>
              <UserDetailsPage />
            </React.Suspense>
          } />
        </Route>
        <Route path="spaces" element={
          <RequireAuth admin>
            <Outlet />
          </RequireAuth>
        }>
          <Route index element={
            <React.Suspense fallback={<></>}>
              <SpacesPage />
            </React.Suspense>
          } />
          <Route path=":spaceId" element={
            <React.Suspense fallback={<></>}>
              <SpaceDetailsPage />
            </React.Suspense>
          } />
        </Route>
        <Route path="templates" element={
          <RequireAuth>
            <Outlet />
          </RequireAuth>
        }>
          <Route index element={
            <React.Suspense fallback={<></>}>
              <TemplatesPage />
            </React.Suspense>
          } />
          <Route path=":templateId" element={
            <React.Suspense fallback={<></>}>
              <TemplateDetailsPage />
            </React.Suspense>
          } />
        </Route>
        <Route path="accounts" element={
          <RequireAuth admin>
            <React.Suspense fallback={<></>}>
              <AccountsPage />
            </React.Suspense>
          </RequireAuth>
        }/>
        <Route path="statistics" element={
          <RequireAuth admin>
            <React.Suspense fallback={<></>}>
              <StatisticsUsagePage />
            </React.Suspense>
          </RequireAuth>
        }/>
        <Route path="storage-activity" element={
          <RequireAuth superAdmin>
            <React.Suspense fallback={<></>}>
              <StatisticsStoragePage />
            </React.Suspense>
          </RequireAuth>
        }/>
        <Route path="system-notifications" element={
          <RequireAuth superAdmin>
            <Outlet />
          </RequireAuth>
        }>
          <Route index element={
            <React.Suspense fallback={<></>}>
              <SystemNotificationsPage />
            </React.Suspense>
          } />
          <Route path=":notificationId" element={
            <React.Suspense fallback={<></>}>
              <SystemNotificationsDetailsPage />
            </React.Suspense>
          } />
        </Route>
        <Route path="platforms" element={
          <RequireAuth superAdmin>
            <Outlet />
          </RequireAuth>
        }>
          <Route index element={
            <React.Suspense fallback={<></>}>
              <PlatformsPage />
            </React.Suspense>
          } />
          <Route path=":platformId" element={
            <React.Suspense fallback={<></>}>
              <PlatformDetailsPage />
            </React.Suspense>
          } />
        </Route>
        <Route path="settings" element={
          <RequireAuth superAdmin>
            <React.Suspense fallback={<></>}>
              <SettingsPage />
            </React.Suspense>
          </RequireAuth>
        }/>
      </Route>
      <Route path="resume-session/:userId" element={
        <React.Suspense fallback={<></>}>
          <ResumeAuthPage />
        </React.Suspense>
      }/>
      <Route path="s/:campaignId/:nodeId" element={
        <React.Suspense fallback={<></>}>
          <ExternalPreviewPage />
        </React.Suspense>
      }/>
      <Route path="callback/oauth" element={
        <React.Suspense fallback={<></>}>
          <CallbackOauth />
        </React.Suspense>
      }/>
      <Route path="*" element={<Navigate to="/campaigns" />} />
    </Routes>
  );
}

export default AppRoutes;
