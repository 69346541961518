import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@headlessui/react';

import { RENDERING_NOTIFICATION } from 'constants/notifications';

import NotificationListItem from './notification-list-item';
import RendingeringItem from './rendering/list-item';

const NotificationList = React.forwardRef(({ notifications }, ref) => {
  return (
    <Menu.Items ref={ref} className="origin-top-right absolute right-0 mt-2 w-96 max-h-96 overflow-auto rounded-md shadow-lg z-40 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
      {notifications.map((item) => (
        <Menu.Item key={item.ts}>
          <>
            {item.component === RENDERING_NOTIFICATION && (
              <RendingeringItem
                ts={item.ts}
                {...item.payload}
              />
            )}
            {!item.component && (
              <NotificationListItem
                title={item.title}
                description={item.description}
                information={item.information}
                type={item.type}
                ts={item.ts}
              />
            )}
          </>
        </Menu.Item>
      ))}
    </Menu.Items>
  );
});

NotificationList.propTypes = {
  notifications: PropTypes.array,
};

NotificationList.defaultProps = {
  notifications: [],
};

export default NotificationList;
