import { Fragment } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';

import { Transition } from '@headlessui/react';
import { CheckCircleIcon, XCircleIcon, InformationCircleIcon, ExclamationIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { FEATURE_BANNER, FEATURE_VIDEO, FEATURE_LABELS, FEATURES_ALL } from 'constants/features';
import { pl } from 'utils/text';

function RenderingNotification({ visible, toastId, type, summary, title, campaignType }) {
  return (
    <Transition
      show={visible}
      appear={true}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              {type === 'success' && <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />}
              {type === 'error' && <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />}
              {type === 'warning' && <ExclamationIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />}
              {type === 'info' && <InformationCircleIcon className="h-6 w-6 text-indigo-400" aria-hidden="true" />}
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium text-gray-900">{title}</p>
              <div className="flex flex-row text-sm items-center">
                <CheckCircleIcon className="w-4 h-4 text-green-500 mr-1"/>
                <div className="text-gray-500">{pl(summary.succeeded, FEATURE_LABELS[campaignType])} completed</div>
              </div>
              {campaignType !== FEATURE_BANNER && (
                <div className="flex flex-row text-sm items-center">
                  <ExclamationIcon className="w-4 h-4 text-yellow-500 mr-1"/>
                  <div className="text-gray-500">{pl(summary.canceled, FEATURE_LABELS[campaignType])} canceled</div>
                </div>
              )}
              <div className="flex flex-row text-sm items-center">
                <ExclamationCircleIcon className="w-4 h-4 text-red-500 mr-1"/>
                <div className="text-gray-500">{pl(summary.failed, FEATURE_LABELS[campaignType])} failed</div>
              </div>
            </div>
            <div className="ml-4 flex-shrink-0 flex">
              <button
                className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => toast.dismiss(toastId)}
              >
                <span className="sr-only">Close</span>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
}

RenderingNotification.propTypes = {
  visible: PropTypes.bool.isRequired,
  toastId: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['success', 'error', 'warning', 'info']),
  summary: PropTypes.object,
  title: PropTypes.string,
  campaignType: PropTypes.oneOf(FEATURES_ALL),
};

RenderingNotification.defaultProps = {
  type: 'success',
  summary: {},
  title: '',
  campaignType: FEATURE_VIDEO,
};

export default RenderingNotification;
