import { useCallback } from 'react';
import { useQuery } from 'react-query';
import * as usersService from 'services/api/users';

import { dateApiResponse } from 'utils/date';

function useUserDetails(userId = 'current') {
  const result = useQuery(
    ['user', userId],
    () => {
      if (userId === 'current') {
        return usersService.fetchCurrentUser();
      }
      return usersService.fetchUser(userId);
    },
    {
      select: useCallback((data) => dateApiResponse(data), []),
      enabled: !!userId,
    },
  );

  return result;
}

export default useUserDetails;
