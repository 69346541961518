import client from './client';

export async function fetchAllSystemNotifications() {
  const result = await client.get('/system-notifications');
  return result.data;
}

export async function fetchOpenSystemNotifications() {
  const result = await client.get('/announcements');
  return result.data;
}

export async function createSystemNotification(data) {
  const result = await client.post('/system-notifications', {
    title: data.title,
    message: data.message,
    type: data.type,
    organization_id: data.organization_id,
    active: data.active,
    started_at: data.started_at,
    ended_at: data.ended_at,
  });
  return result.data;
}

export async function updateSystemNotification(data) {
  const result = await client.put(`/system-notifications/${data.id}`, data);

  return result.data;
}

export async function deleteSystemNotification(id) {
  const result = await client.delete(`/system-notifications/${id}`);

  return result.data;
}
