import { useCallback } from 'react';
import { useQuery } from 'react-query';
import * as templatesService from 'services/api/templates';

import { dateApiResponse } from 'utils/date';

function useTemplate(templateId) {
  const result = useQuery(
    ['template', templateId],
    () => templatesService.fetchTemplate(templateId),
    {
      enabled: !!templateId,
      select: useCallback((data) => {
        const sortedData = [
          ...data.map((data) => dateApiResponse(data)),
        ].sort((a, b) => b.version - a.version);

        return {
          current: sortedData[0], // sortedData.pop(),
          versions: sortedData,
        };
      }, []),
    },
  );

  return result;
}

export default useTemplate;
