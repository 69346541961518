import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { CheckCircleIcon, XCircleIcon, InformationCircleIcon, ExclamationIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import { FEATURE_BANNER, FEATURE_VIDEO, FEATURE_LABELS, FEATURES_ALL } from 'constants/features';
import { pl } from 'utils/text';

function RenderingItem({ type, summary, title, ts, campaignType }) {
  return (
    <div className="max-w-sm w-full bg-white pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
      <div className="p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0">
            {type === 'success' && <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />}
            {type === 'error' && <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />}
            {type === 'warning' && <ExclamationIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />}
            {type === 'info' && <InformationCircleIcon className="h-6 w-6 text-indigo-400" aria-hidden="true" />}
          </div>
          <div className="relative ml-3 w-0 flex-1 pt-0.5">
            <p className="text-sm font-medium text-gray-900">{title}</p>
            <div className="flex flex-row text-sm items-center">
              <CheckCircleIcon className="w-4 h-4 text-green-500 mr-1"/>
              <div className="text-gray-500">{pl(summary.succeeded, FEATURE_LABELS[campaignType])} completed</div>
            </div>
            {campaignType !== FEATURE_BANNER && (
              <div className="flex flex-row text-sm items-center">
                <ExclamationIcon className="w-4 h-4 text-yellow-500 mr-1"/>
                <div className="text-gray-500">{pl(summary.canceled, FEATURE_LABELS[campaignType])} canceled</div>
              </div>
            )}
            <div className="flex flex-row text-sm items-center">
              <ExclamationCircleIcon className="w-4 h-4 text-red-500 mr-1"/>
              <div className="text-gray-500">{pl(summary.failed, FEATURE_LABELS[campaignType])} failed</div>
            </div>
            <p className="absolute top-0 right-0 mt-1 text-xs text-gray-400 text-right">{dayjs(ts).fromNow()}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

RenderingItem.propTypes = {
  title: PropTypes.string,
  summary: PropTypes.object,
  type: PropTypes.oneOf(['success', 'error', 'info', 'warning']),
  ts: PropTypes.number,
  campaignType: PropTypes.oneOf(FEATURES_ALL),
};

RenderingItem.defaultProps = {
  title: '',
  summary: {},
  type: 'success',
  ts: dayjs().valueOf(),
  campaignType: FEATURE_VIDEO,
};

export default RenderingItem;
