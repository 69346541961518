import { useCallback } from 'react';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';
import useStore from 'store';
import Notification from 'features/notifications/notification';
import RenderingNotification from 'features/notifications/rendering/notification';
import { RENDERING_NOTIFICATION } from 'constants/notifications';

function useNotification() {
  const addNotification = useStore(state => state.addNotification);
  const showNotification = useCallback(({ title, description, information, type, options = {} }) => {
    addNotification({
      title,
      description,
      information,
      type,
      ts: dayjs().valueOf(),
    });
    toast.custom((t) => (
      <Notification
        toastId={t.id}
        visible={t.visible}
        title={title}
        description={description}
        information={information}
        type={type}
      />
    ), options);
  }, [addNotification]);

  const showCustomNotification = useCallback(({ component, payload, options = {} }) => {
    addNotification({
      component,
      payload,
      ts: dayjs().valueOf(),
    });
    if (component === RENDERING_NOTIFICATION) {
      toast.custom((t) => (
        <RenderingNotification
          toastId={t.id}
          visible={t.visible}
          {...payload}
        />
      ), options);
    }
  }, [addNotification]);

  return {
    showNotification,
    showCustomNotification,
  };
}

export default useNotification;
