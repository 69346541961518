import { fetchEventSource } from '@microsoft/fetch-event-source';

import { getBaseUrl } from 'utils/domain';

class RetriableError extends Error { }
class FatalError extends Error { }

class EventHub {
  constructor() {
    this.events = {};
    this.wildcardListeners = [];
    this.controller = null;
    this.token = '';
    this.organisationId = '';
  }

  setOrganisation(id) {
    if (this.organisationId !== id) {
      this.organisationId = id;
      if (this.token) {
        this.open();
      }
    }
  }

  setToken(token) {
    if (this.token !== token) {
      this.token = token;
      if (this.organisationId) {
        this.open();
      }
    }
  }

  open() {
    if (this.controller) {
      this.controller.abort();
    }
    this.controller = new AbortController();
    const that = this;
    try {
      fetchEventSource(`${getBaseUrl(window.location.host)}sse`, {
      // fetchEventSource(`http://localhost:5000/sse`, {
        method: 'GET',
        headers: {
          Accept: 'text/event-stream',
          Authorization: `Bearer ${this.token}`,
          'organization-id': this.organisationId,
        },
        signal: this.controller.signal,
        openWhenHidden: true,
        async onopen(response) {
          if (response.ok && response.headers.get('content-type') === 'text/event-stream') {
            // ok
            window.queryClient.invalidateQueries('backend-version');
            return;
          } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
            // client-side errors are usually non-retriable:
            throw new FatalError();
          } else {
            throw new RetriableError();
          }
        },
        onmessage(msg) {
          if (msg.event) {
            if (msg.event === 'FatalError') {
              throw new FatalError(msg.data);
            }
            const payload = JSON.parse(msg.data);
            that.emit(payload.data.title, payload.data.data);
          }
        },
        onclose() {
          throw new RetriableError();
        },
        onerror(err) {
          if (err instanceof FatalError) {
            throw err; // rethrow to stop the operation
          }
          return 10000; // retry interval
          // throw err; // throwing to prevent retrying
        },
      });
    } catch (err) {
      if (err instanceof RetriableError) {
        this.open();
      } else {
        throw err;
      }
    }
  }

  close() {
    if (this.controller) {
      this.controller.abort();
      this.controller = null;
      this.token = null;
    }
  }

  subscribeAll(callback) {
    this.wildcardListeners.push(callback);
    return {
      unsubscribe: () => {
        this.wildcardListeners.splice(this.wildcardListeners.indexOf(callback), 1);
      }
    };
  }

  subscribe(topic, callback) {
    (this.events[topic] || (this.events[topic] = [])).push(callback);
    return {
      unsubscribe: () => {
        this.events[topic] && this.events[topic].splice(
          this.events[topic].indexOf(callback),
          1,
        );
      }
    };
  }

  emit(topic, data) {
    (this.events[topic] || []).forEach((fn) => fn(topic, data));
    this.wildcardListeners.forEach((fn) => fn(topic, data));
  }
}

const eventHub = new EventHub();
export default eventHub;
