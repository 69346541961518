import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const CustomButton = React.forwardRef(({ children, srLabel, disabled, className, onClick, ...props }, ref) => {
  return (
    <button
      ref={ref}
      className={classNames(
        'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
        className,
      )}
      disabled={disabled}
      onClickCapture={onClick}
      type="button"
      {...props}
    >
      {srLabel && (<span className="sr-only">{srLabel}</span>)}
      {children}
    </button>
  );
});

CustomButton.propTypes = {
  disabled: PropTypes.bool,
  srLabel: PropTypes.string,
  onClick: PropTypes.func,
};

CustomButton.defaultProps = {
  disabled: false,
  srLabel: '',
  onClick: () => {},
};

export default CustomButton;
