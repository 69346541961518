import { Fragment, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Disclosure, Menu, Transition, Popover } from '@headlessui/react';
import { NavLink, useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import useStore from 'store';

import useCurrentOrganisation from 'hooks/use-current-organisation';

import NotificationList from 'features/notifications/notification-list';

import { FEATURES_ALL } from 'constants/features';

import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline';
import { UserIcon, ChevronDownIcon } from '@heroicons/react/solid';

import useAuth from 'hooks/use-auth';
import useMe from 'hooks/use-me';
import useOrganisationFeatures from 'hooks/use-organisation-features';

import { isAdOn } from 'utils/domain';

import Usage from './usage';

import { ReactComponent as SunfishLogo } from 'assets/sunfish.svg';

const NAVIGATION = [
  { name: 'Campaigns', to: '/campaigns' },
  { name: 'Templates', to: '/templates' },
  { name: 'Organization', entries: [
    { name: 'Info', to: '/organization' },
    { name: 'Spaces', to: '/spaces' },
    { name: 'Users', to: '/users' },
    // { name: 'Metadata', to: '/users' },
    { name: 'External Accounts', to: '/accounts' },
  ], admin: true, matchChildren: true },
  { name: 'Analysis', entries: [
    { name: 'Logs', to: '/logs' },
    { name: 'Statistics', to: '/statistics' },
  ], admin: true, matchChildren: true },
  { name: 'Contact', entries: [
    { name: 'Buy Storage', to: '/contact/storage' },
    { name: 'Request Features', to: '/contact/features' },
    { name: 'Get in Touch', to: '/contact/message' },
  ], admin: true, matchChildren: true },
];

const SUPER_ADMIN_NAVIGATION = [
  { name: 'Organizations', to: '/organizations' },
  { name: 'Platforms', to: '/platforms' },
  { name: 'Statistics', to: '/statistics' },
  { name: 'Storage Activity', to: '/storage-activity' },
  { name: 'System Notifications', to: '/system-notifications' },
  { name: 'Settings', to: '/settings' },
];

const USER_NAVIGATION = [
  { name: 'Your Profile', to: '/profile' },
];

const filterEntry = (item, auth) =>
  (!item.admin && !item.owner && !item.superadmin)
  || (item.admin && auth.isAdmin)
  || (item.owner && auth.isOwner)
  || (item.superadmin && auth.isSuperAdmin);

function NavigationBar() {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const auth = useAuth();
  const user = useMe();
  const currentOrganisation = useCurrentOrganisation();
  const notifications = useStore(state => state.notifications);
  const hasUnreadNotifications = useStore(state => state.unread);
  const confirmReadNotification = useStore(state => state.confirmReadNotification);
  const { data: organisationFeatureData } = useOrganisationFeatures();

  const matchQuery = useCallback((params = {}) => {
    return Object.entries(params)
      .filter(([key, value]) => searchParams.get(key) !== value)
      .length === 0;
  }, [searchParams]);

  const matchChildren = useCallback((item) => {
    return item.entries
      .filter((entry) => location.pathname.endsWith(
        entry.to.split('?')[0],
      ))
      .length > 0;
  }, [location]);

  const navigation = useMemo(() => {
    if (auth.useSuperAdmin) {
      return SUPER_ADMIN_NAVIGATION;
    }
    const missingFeatures = FEATURES_ALL.filter((feat) => !organisationFeatureData?.includes(feat));
    const entry = NAVIGATION
      .find((navElement) => navElement.name === 'Contact')
      .entries?.find((entry) => entry.to === '/contact/features');
    if (entry) {
      entry.hidden = missingFeatures.length === 0;
    }
    return NAVIGATION.filter((item) => filterEntry(item, {
      isSuperAdmin: auth.isSuperAdmin,
      isOwner: auth.isOwner,
      isAdmin: auth.isAdmin,
    }))
      .map((entry) => ({
        ...entry,
        entries: entry.entries?.filter((item) => !item.hidden && filterEntry(item, {
          isSuperAdmin: auth.isSuperAdmin,
          isOwner: auth.isOwner,
          isAdmin: auth.isAdmin,
        })) || null,
      }));
  }, [auth.useSuperAdmin, auth.isAdmin, auth.isOwner, auth.isSuperAdmin, organisationFeatureData]);

  const userNavigation = USER_NAVIGATION;

  const navigate = useNavigate();

  function handleSignOut() {
    auth.signout();
    navigate('/', { replace: true });
  }

  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  {currentOrganisation.isSuccess && (
                    <>
                      {currentOrganisation.data.logo?.url ? (
                        <div
                          className="h-16 w-24 bg-left bg-no-repeat bg-contain"
                          style={{
                            backgroundImage: `url(${currentOrganisation.data.logo?.url})`,
                          }}
                        />
                      ) : (
                        <>
                          {isAdOn() ? (
                            <h1 className="text-xl font-bold leading-tight text-white">Ad-On</h1>
                          ) : (
                            <SunfishLogo className="w-36 text-white" />
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>

                <div className="hidden lg:block">
                  <div className={classNames(
                    'flex items-baseline space-x-4',
                    currentOrganisation.data?.logo?.url || isAdOn() ? 'ml-10' : 'ml-4',
                  )}>
                    {navigation.map((item) => !item.entries ? (
                      <NavLink
                        key={item.name}
                        to={item.to}
                        className={({ isActive }) => classNames(
                          isActive
                            ? 'bg-gray-900 text-white'
                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'px-3 py-2 rounded-md text-sm font-medium'
                        )}
                      >
                        {item.name}
                      </NavLink>
                    ) : (
                      <Popover
                        key={item.name}
                        className="relative"
                      >
                        {({ open }) => (
                          <>
                            <Popover.Button
                              className={classNames(
                                open || (item.matchChildren && matchChildren(item))
                                  ? 'bg-gray-900 text-white'
                                  : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                'group inline-flex items-center px-3 py-2 rounded-md text-sm font-medium focus:outline-none'
                              )}
                            >
                              <span>{item.name}</span>
                              <ChevronDownIcon
                                className="text-gray-300 group-hover:text-white ml-2 h-5 w-5 -mr-1"
                                aria-hidden="true"
                              />
                            </Popover.Button>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-150"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 translate-y-1"
                            >
                              <Popover.Panel className="absolute left-1/2 z-40 mt-1.5 w-48 max-w-xs -translate-x-1/2 transform px-2 sm:px-0">
                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                  <div className="relative grid gap-2 bg-white p-2">
                                    {item.entries.map((entry) => (
                                      <Popover.Button
                                        key={entry.name}
                                        as={NavLink}
                                        to={entry.to}
                                        className={() => ({ isActive }) => classNames(
                                          isActive && matchQuery(entry.matchQuery)
                                            ? 'bg-gray-900 text-white'
                                            : 'text-gray-500 hover:bg-gray-700 hover:text-white',
                                          'px-3 py-2 rounded-md text-sm font-medium'
                                        )}
                                      >
                                        {entry.name}
                                      </Popover.Button>
                                    ))}
                                  </div>
                                </div>
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                    ))}
                  </div>
                </div>
              </div>
              <div className="hidden lg:block">
                <div className="ml-6 flex items-center">
                  {/* Usage */}
                  {!auth.useSuperAdmin && (<Usage />)}
                  {/* Notification dropdown */}
                  <Menu as="div" className="mx-3 relative">
                    <div>
                      <Menu.Button className={classNames(
                        'max-w-xs flex items-center text-sm bg-gray-800 rounded-full hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white',
                        {
                          'text-gray-400': !hasUnreadNotifications,
                          'text-white': hasUnreadNotifications,
                        }
                      )}>
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                        {hasUnreadNotifications && <span className="absolute top-0 right-0 inline-block w-2 h-2 transform translate-x-1/2 -translate-y-1/2 bg-yellow-600 rounded-full"></span>}
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                      afterLeave={confirmReadNotification}
                    >
                      <NotificationList notifications={notifications} />
                    </Transition>
                  </Menu>
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative">
                    <div>
                      <Menu.Button className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                        <span className="sr-only">Open user menu</span>
                        {user.imageUrl
                          ? <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" />
                          : <UserIcon className="h-6 w-6" aria-hidden="true" />
                        }
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40">
                        {userNavigation.map((item) => (
                          <Menu.Item
                            key={item.name}
                            as={NavLink}
                            to={item.to}
                            className={() => ({ isActive }) => classNames(
                              isActive ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            )}
                          >
                            {item.name}
                          </Menu.Item>
                        ))}
                        <Menu.Item key="sign-out">
                          <button className="block px-4 py-2 text-sm text-gray-700" onClick={handleSignOut}>
                            Sign out
                          </button>
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
              <div className="-mr-2 flex lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {navigation.map((item) => !item.entries ? (
                <Disclosure.Button
                  key={item.name}
                  as={NavLink}
                  to={item.to}
                  className={() => ({ isActive }) => classNames(
                    isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium',
                  )}
                >
                  {item.name}
                </Disclosure.Button>
              ) : (
                <Disclosure
                  key={item.name}
                  defaultOpen={matchChildren(item)}
                >
                  <Disclosure.Button
                    className={classNames(
                      (item.matchChildren && matchChildren(item))
                        ? 'bg-gray-900 text-white'
                        : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'group flex items-center justify-between w-full px-3 py-2 rounded-md text-base font-medium focus:outline-none'
                    )}
                  >
                    <span>{item.name}</span>
                    <ChevronDownIcon
                      className="text-gray-300 group-hover:text-white ml-2 h-5 w-5 -mr-1"
                      aria-hidden="true"
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <div className="relative grid gap-2 p-2">
                      {item.entries.map((entry) => (
                        <NavLink
                          key={entry.name}
                          to={entry.to}
                          className={({ isActive }) => classNames(
                            isActive && matchQuery(entry.matchQuery)
                              ? 'bg-gray-900 text-white'
                              : 'text-gray-500 hover:bg-gray-700 hover:text-white',
                            'px-3 py-2 rounded-md text-sm font-medium'
                          )}
                        >
                          {entry.name}
                        </NavLink>
                      ))}
                    </div>
                  </Disclosure.Panel>
                </Disclosure>
              ))}
            </div>
            <div className="pt-4 pb-3 border-t border-gray-700">
              <div className="flex items-center px-5 justify-between">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    {user.imageUrl
                      ? <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                      : <UserIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    }
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-white">{`${user.details.first_name} ${user.details.last_name}`}</div>
                    <div className="text-sm font-medium text-gray-400">{user.email}</div>
                  </div>
                </div>
              </div>
              <div className="mt-3 px-2 space-y-1">
                {userNavigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as={NavLink}
                    to={item.to}
                    className={() => ({ isActive }) => classNames(
                      isActive ? 'bg-gray-900' : '',
                      'block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700',
                    )}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
                <Disclosure.Button
                  key="sign-out"
                  as={Fragment}
                >
                  <button
                    className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                    onClick={handleSignOut}
                  >
                    Sign out
                  </button>
                </Disclosure.Button>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default NavigationBar;
