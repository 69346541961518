import client from './client';

export async function fetchCampaigns(viewTrash = false) {
  const result = await client.get('/campaigns', {
    params: {
      perPage: 1000,
      trashed: viewTrash,
    }
  });
  return result.data;
}

export async function fetchCampaign(campaignId) {
  if (campaignId) {
    const result = await client.get(`/campaigns/${campaignId}`);
    return result.data;
  }
  return {};
}

export async function createCampaign(data) {
  const result = await client.post('/campaigns', {
    name: data.name,
    description: data.description,
    spaces: [data.spaces],
    template_id: data.template_id,
    details: {
      tags: data.details.tags,
    },
  });
  return result.data;
}

export async function updateCampaign(campaignId, data) {
  const result = await client.put(`/campaigns/${campaignId}`, {
    name: data.name,
    description: data.description,
    details: {
      tags: data.details.tags,
    },
  });

  return result.data;
}

export async function updateCampaignTemplate(campaignId, templateId) {
  const result = await client.put(`/campaigns/${campaignId}`, {
    template_id: templateId,
  });

  return result.data;
}

export async function duplicateCampaign(campaignId) {
  const result = await client.put(`/campaigns/clone/${campaignId}`, {});
  return result.data;
}

export async function deleteCampaign(campaignId, force = false) {
  const result = await client.delete(`/campaigns/${campaignId}`, {
    params: {
      force,
    }
  });
  return result.data;
}

export async function restoreCampaign(campaignId) {
  const result = await client.put(`/campaigns/undelete/${campaignId}`, {});
  return result.data;
}

export async function transferCampaign(campaignId, spaceId) {
  const result = await client.put(`/spaces/${spaceId}/campaigns/${campaignId}`, {});
  return result.data;
}

export async function generateSignedLink(campaignId) {
  const result = await client.get(`/encrypt/${campaignId}`);
  return result.data;
}

export async function fetchPreview(campaignId, key) {
  const result = await client.get(`/campaigns/share/${campaignId}`, {
    params: {
      key,
    },
  });
  return result.data;
}

export async function fetchTags() {
  const result = await client.get('/campaigns/tags');
  return result.data;
}