import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CheckIcon } from '@heroicons/react/outline';

import Spinner from 'components/spinner';

const COLOR = {
  primary: 'border-transparent border shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
  secondary: 'border-transparent border shadow-sm text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
  danger: 'border-transparent border shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500',
  success: 'border-transparent border shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500',
  outlined: 'border-gray-300 border shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
  flat: 'border-transparent border text-indigo-600 hover:text-indigo-900 focus:ring-0 focus:ring-offset-0 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
};

const PADDING = {
  xs: 'px-2.5 py-1.5',
  sm: 'px-3 py-2',
  md: 'px-4 py-2',
  lg: 'px-4 py-2',
  xl: 'px-6 py-3',
};

const SIZE = {
  xs: 'text-xs font-medium',
  sm: 'text-sm leading-4 font-medium',
  md: 'text-sm font-medium',
  lg: 'text-base font-medium',
  xl: 'text-base font-medium',
};

const ROUNDED = {
  xs: 'rounded',
  sm: 'rounded-md',
  md: 'rounded-md',
  lg: 'rounded-md',
  xl: 'rounded-md',
};

const ICON_SIZE = {
  xs: 'h-5 w-5',
  sm: 'h-5 w-5',
  md: 'h-5 w-5',
  lg: 'h-6 w-6',
  xl: 'h-6 w-6',
};

const ICON_LABEL_CLASSES = {
  xs: '-ml-0.5 sm:mr-2',
  sm: '-ml-1 sm:mr-2',
  md: '-ml-1 sm:mr-3',
  lg: '-ml-1 sm:mr-3',
  xl: '-ml-1 sm:mr-3',
};

const Button = React.forwardRef(({
  size,
  iconSize,
  color,
  rounded,
  className,
  icon,
  label,
  successLabel,
  srLabel,
  isProcessing,
  isSuccess,
  ...props
}, ref) => {
  const Icon = icon;

  const [success, setSuccess] = React.useState(false);

  useEffect(() => {
    if (isSuccess) {
      setSuccess(true);
      const id = setTimeout(() => setSuccess(false), 20000);

      return () => clearTimeout(id);
    } else {
      setSuccess(false);
    }
  }, [isSuccess]);

  return (
    <button
      ref={ref}
      className={classNames(
        'flex',
        SIZE[size],
        {
          [PADDING[size]]: !rounded,
          'rounded-full': rounded,
          [ROUNDED[size]]: !rounded,
        },
        COLOR[success ? 'success' : color],
        className,
      )}
      disabled={isProcessing}
      {...props}
    >
      <div className="flex items-center relative m-auto max-w-full">
        {srLabel && (<span className="sr-only">{srLabel}</span>)}
        {isProcessing && (<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <Spinner size="xs" color="white" />
        </div>)}
        {icon && (
          success ? (
            <CheckIcon className={classNames(
              'min-w-[1.25rem]',
              ICON_SIZE[iconSize || size],
              { [ICON_LABEL_CLASSES[size]]: !!label },
              { 'invisible': isProcessing },
            )} aria-hidden="true"/>
          ) : (
            <Icon className={classNames(
              'min-w-[1.25rem]',
              ICON_SIZE[iconSize || size],
              { [ICON_LABEL_CLASSES[size]]: !!label },
              { 'invisible': isProcessing },
            )} aria-hidden="true"/>
          )
        )}
        <span className={
          classNames(
            'truncate max-w-full',
            { 'hidden sm:inline-block': !!icon },
            { 'invisible': isProcessing },
          )
        }>{success ? successLabel || label : label}</span>
      </div>
    </button>
  );
});

Button.propTypes = {
  label: PropTypes.string,
  successLabel: PropTypes.string,
  icon: PropTypes.object,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  iconSize: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  color: PropTypes.oneOf(['primary', 'secondary', 'danger', 'outlined', 'flat', 'success']),
  rounded: PropTypes.bool,
  type: PropTypes.string,
  srLabel: PropTypes.string,
  isProcessing: PropTypes.bool,
  isSuccess: PropTypes.bool,
};

Button.defaultProps = {
  size: 'md',
  color: 'primary',
  type: 'button',
  rounded: false,
  srLabel: '',
  isProcessing: false,
  isSuccess: false,
};

export default Button;
