import { persist } from 'zustand/middleware';

const createAuthSlice = persist((set) => ({
  currentUser: null,
  credentials: null,
  signin: (user, token, expiresAt) => set({
    currentUser: user,
    credentials: {
      token,
      expiresAt,
    },
  }),
  signout: () => set({ currentUser: null, credentials: null }),
}), {
  name: 'auth',
});

export default createAuthSlice;
