export const SYSTEM_NOTIFICATION_INFO = 'information';
export const SYSTEM_NOTIFICATION_MAINTENANCE = 'maintenance';
export const SYSTEM_NOTIFICATION_CRITICAL = 'critical';

// The "superadmin" type is only used for locally generated notifications, so it's not included in the lists below
export const SYSTEM_NOTIFICATION_SUPERADMIN = 'superadmin';

export const SYSTEM_NOTIFICATION_TYPES = [
  SYSTEM_NOTIFICATION_INFO,
  SYSTEM_NOTIFICATION_MAINTENANCE,
  SYSTEM_NOTIFICATION_CRITICAL,
];

export const SYSTEM_NOTIFICATION_COLOR = {
  [SYSTEM_NOTIFICATION_INFO]: 'primary',
  [SYSTEM_NOTIFICATION_MAINTENANCE]: 'warning',
  [SYSTEM_NOTIFICATION_CRITICAL]: 'danger',
};

export const SYSTEM_NOTIFICATION_TITLE = {
  [SYSTEM_NOTIFICATION_INFO]: 'Information',
  [SYSTEM_NOTIFICATION_MAINTENANCE]: `Maintenance`,
  [SYSTEM_NOTIFICATION_CRITICAL]: `Critical`,
};

export const SYSTEM_NOTIFICATION_MESSAGE_PLACEHOLDER = {
  [SYSTEM_NOTIFICATION_INFO]: 'Enter your information message here.',
  [SYSTEM_NOTIFICATION_MAINTENANCE]: `Enter your maintenance message here.`,
  [SYSTEM_NOTIFICATION_CRITICAL]: `Enter your warning message here.`,
};
