import client from './client';

export async function fetchCurrentUser() {
  const result = await client.get('/profile');
  return result.data;
}

function updateUserPayload(data) {
  return {
    email: data.email,
    details: {
      first_name: data.details.first_name,
      last_name: data.details.last_name,
      display_name: data.details.display_name,
    },
    role: data.role,
  };
}

export async function updateCurrent(data) {
  const result = await client.post('/profile', updateUserPayload(data));

  return result.data;
}

export async function fetchUser(userId) {
  if (userId) {
    const result = await client.get(`/users/${userId}`);
    return result.data;
  }
  return {};
}

export async function updateUser(userId, data) {
  const result = await client.put(`/users/${userId}`, updateUserPayload(data));

  return result.data;
}

export async function fetchUsers() {
  const result = await client.get('/users', {
    params: {
      perPage: 1000,
    },
  });
  return result.data;
}

export async function createUser(data) {
  const result = await client.post('/users', {
    email: data.email,
    details: {
      first_name: data.details.first_name,
      last_name: data.details.last_name,
    },
    role: data.role,
  });
  return result.data;
}

export async function deactivateUser(userId) {
  const result = await client.delete(`/users/${userId}`);
  return result.data;
}

export async function resendInvite(userId, isOwner = false) {
  if (userId) {
    if (isOwner) {
      const resultOwnerInvite = await client.put(`/owners/invite/${userId}`);
      return resultOwnerInvite.data;
    }
    const resultUserInvite = await client.put(`/users/invite/${userId}`);
    return resultUserInvite.data;
  }
  return {};
}

export async function uploadProfilePicture(data) {
  const formData = new FormData();
  formData.append('file', data);

  const result = await client.post('/profile/image', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });

  return result.data;
}

export async function deleteProfilePicture() {
  const result = await client.delete('/profile/image');

  return result.data;
}
