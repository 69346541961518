import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isoWeek from 'dayjs/plugin/isoWeek';

import localeDE from 'dayjs/locale/de';

dayjs.extend(isToday);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);
dayjs.extend(isSameOrBefore);
dayjs.extend(isoWeek);

dayjs.locale('en');

dayjs.updateLocale('en', {
  formats: localeDE.formats,
  weekStart: 1,
});
