import { NavLink } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

import SpaceBreadcrumb from 'features/spaces/space-breadcrumb';
import PlatformBreadcrumb from 'features/platforms/platform-breadcrumb';
import TemplateBreadcrumb from 'features/templates/template-breadcrumb';
import CampaignBreadcrumb from 'features/campaigns/campaign-breadcrumb';
import UserBreadcrumb from 'features/users/user-breadcrumb';
import SystemNotificationsBreadcrumb from 'features/system-notifications/system-notifications-breadcrumb';

import { HomeIcon } from '@heroicons/react/solid';

const ROUTES = [
  { path: '/spaces/:spaceId', breadcrumb: SpaceBreadcrumb },
  { path: '/platforms/:platformId', breadcrumb: PlatformBreadcrumb },
  { path: '/templates/:templateId', breadcrumb: TemplateBreadcrumb },
  { path: '/campaigns/:campaignId', breadcrumb: CampaignBreadcrumb },
  { path: '/users/:userId', breadcrumb: UserBreadcrumb },
  { path: '/system-notifications/:notificationId', breadcrumb: SystemNotificationsBreadcrumb },
];

function NavigationHeader() {
  const breadcrumbs = useBreadcrumbs(ROUTES);

  return (
    <header className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <nav className="flex" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            <li>
              <div>
                <NavLink to="/" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                  <span className="sr-only">Home</span>
                </NavLink>
              </div>
            </li>
            {breadcrumbs.slice(1).map(({ match, breadcrumb }) => (
              <li key={match.pathname}>
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 h-5 w-5 text-gray-300"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                  </svg>
                  <NavLink
                    to={match.pathname}
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  >
                    {breadcrumb}
                  </NavLink>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>
    </header>
  );
}

export default NavigationHeader;
