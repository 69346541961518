import classNames from 'classnames';
import { ExclamationIcon, XIcon } from '@heroicons/react/outline';

import { SYSTEM_NOTIFICATION_CRITICAL, SYSTEM_NOTIFICATION_INFO, SYSTEM_NOTIFICATION_MAINTENANCE, SYSTEM_NOTIFICATION_SUPERADMIN } from 'constants/system-notifications';

import CustomButton from 'components/custom-button';
import useLocalStorage from 'hooks/use-local-storage';

import useAnnouncements from './use-announcements';
import useSuperadminNotifications from './use-superadmin-notifications';

function Announcements() {
  const { data: announcementsData } = useAnnouncements();
  const superadminNotifications = useSuperadminNotifications();

  const [dismissedAnnouncements, setDismissedAnnouncements] = useLocalStorage('announcements', []);

  const dismiss = (id) => {
    setDismissedAnnouncements([...dismissedAnnouncements, id]);
  };

  const systemAnnouncements = announcementsData || [];

  const allAnnouncements = [...systemAnnouncements, ...superadminNotifications];
  const filteredAnnouncements = allAnnouncements.filter((a) => !dismissedAnnouncements.includes(a.id));

  if (filteredAnnouncements.length === 0) {
    return null;
  }

  return (
    <div className="absolute w-full top-16 z-30">
      {filteredAnnouncements.map((announcement) => (
        <div
          key={announcement.id}
          className={classNames(
            'border-b',
            {
              'bg-yellow-50 border-yellow-200 text-yellow-400': announcement.type === SYSTEM_NOTIFICATION_MAINTENANCE,
              'bg-indigo-50 border-indigo-200 text-indigo-400': announcement.type === SYSTEM_NOTIFICATION_INFO,
              'bg-red-50 border-red-200 text-red-400': announcement.type === SYSTEM_NOTIFICATION_CRITICAL,
              'bg-purple-50 border-purple-200 text-purple-400': announcement.type === SYSTEM_NOTIFICATION_SUPERADMIN,
            },
          )}
        >
          <div className="max-w-7xl mx-auto flex py-4 sm:px-6 lg:px-8">
            <div className="flex-shrink-0">
              <ExclamationIcon className="h-5 w-5" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className={classNames(
                'font-medium text-sm',
                {
                  'text-yellow-800': announcement.type === SYSTEM_NOTIFICATION_MAINTENANCE,
                  'text-indigo-800': announcement.type === SYSTEM_NOTIFICATION_INFO,
                  'text-red-800': announcement.type === SYSTEM_NOTIFICATION_CRITICAL,
                  'text-purple-800': announcement.type === SYSTEM_NOTIFICATION_SUPERADMIN,
                },
              )}>{announcement.title}</h3>
              <div className={classNames(
                'mt-2 text-sm',
                {
                  'text-yellow-700': announcement.type === SYSTEM_NOTIFICATION_MAINTENANCE,
                  'text-indigo-700': announcement.type === SYSTEM_NOTIFICATION_INFO,
                  'text-red-700': announcement.type === SYSTEM_NOTIFICATION_CRITICAL,
                  'text-purple-700': announcement.type === SYSTEM_NOTIFICATION_SUPERADMIN,
                },
              )}>
                <p>
                  {announcement.message}
                </p>
              </div>
            </div>
            <div className="ml-auto pl-3 self-center">
              <div className="-mx-1.5 -my-1.5">
                <CustomButton
                  srLabel="Dismiss"
                  className={classNames(
                    'p-1.5 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2',
                    {
                      'text-yellow-500 hover:bg-yellow-100 focus:ring-yellow-600 focus:ring-offset-yellow-50': announcement.type === SYSTEM_NOTIFICATION_MAINTENANCE,
                      'text-indigo-500 hover:bg-indigo-100 focus:ring-indigo-600 focus:ring-offset-indigo-50': announcement.type === SYSTEM_NOTIFICATION_INFO,
                      'text-red-500 hover:bg-red-100 focus:ring-red-600 focus:ring-offset-red-50': announcement.type === SYSTEM_NOTIFICATION_CRITICAL,
                      'text-purple-500 hover:bg-purple-100 focus:ring-purple-600 focus:ring-offset-purple-50': announcement.type === SYSTEM_NOTIFICATION_SUPERADMIN,
                    },
                  )}
                  onClick={() => dismiss(announcement.id)}
                >
                  <XIcon className="h-5 w-5" aria-hidden="true" />
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Announcements;
