import { create } from 'zustand';

import createAuthSlice from 'features/auth/auth-slice';
import createNotificationsSlice from 'features/notifications/notifications-slice';

const useStore = create((set, get, api) => ({
  ...createAuthSlice(set, get, api),
  ...createNotificationsSlice(set, get, api),
}));

export default useStore;
