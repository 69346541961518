export function pl(value, singular, { pluralisation = null, hideValue = false } = {}) {
  const valueString = hideValue ? '' : `${value} `;
  if (Math.abs(value) !== 1) {
    if (pluralisation) {
      return `${valueString}${pluralisation}`;
    }
    return `${valueString}${singular}s`;
  }
  return `${valueString}${singular}`;
}
