import { Fragment } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';

import { Transition } from '@headlessui/react';
import { CheckCircleIcon, XCircleIcon, InformationCircleIcon, ExclamationIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';

function Notification({ title, description, information, type, visible, toastId }) {
  return (
    <Transition
      show={visible}
      appear={true}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              {type === 'success' && <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />}
              {type === 'error' && <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />}
              {type === 'warning' && <ExclamationIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />}
              {type === 'info' && <InformationCircleIcon className="h-6 w-6 text-indigo-400" aria-hidden="true" />}
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium text-gray-900">{title}</p>
              <p className="mt-1 text-sm text-gray-500">{description}</p>
              {information && (<p className="mt-1.5 text-xs text-gray-400">{information}</p>)}
            </div>
            <div className="ml-4 flex-shrink-0 flex">
              <button
                className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => toast.dismiss(toastId)}
              >
                <span className="sr-only">Close</span>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
}

Notification.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  information: PropTypes.string,
  type: PropTypes.oneOf(['success', 'error', 'warning', 'info']),
  visible: PropTypes.bool.isRequired,
  toastId: PropTypes.string.isRequired,
};

Notification.defaultProps = {
  title: '',
  description: '',
  information: '',
  type: 'success',
};

export default Notification;
