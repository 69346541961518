import { useQuery } from 'react-query';
import * as organisationsService from 'services/api/organisations';
import { setOrganisationHeader } from 'services/api/client';
import EventHub from 'services/events';

function useCurrentOrganisation() {
  const result = useQuery(
    ['current-organisation'],
    () => organisationsService.getCurrent(),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setOrganisationHeader(data.id);
        EventHub.setOrganisation(data.id);
      },
    },
  );
  return result;
}

export default useCurrentOrganisation;
