import { useCallback } from 'react';
import { useQuery } from 'react-query';
import * as systemNotificationService from 'services/api/system-notifications';

import { dateApiResponse } from 'utils/date';

function useSystemNoficiations() {
  const result = useQuery(
    ['system-notifications'],
    () => systemNotificationService.fetchAllSystemNotifications(),
    {
      select: useCallback((data) => data.map((data) => dateApiResponse(data, ['started_at', 'ended_at'])), []),
    }
  );
  return result;
}

export default useSystemNoficiations;
