import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import useEvent from 'hooks/use-event';
import useMe from 'hooks/use-me';
import useNotification from 'hooks/use-notification';
import { RENDERING_NOTIFICATION } from 'constants/notifications';

function NotificationManager() {
  const { showNotification, showCustomNotification } = useNotification();
  const queryClient = useQueryClient();
  const me = useMe();

  const processEvent = useCallback((event, data) => {
    if (event && data) {
      const isUserEvent = data?.userId === me.id;
      if (event === 'backend-error') {
        if (data.type === 'creative') {
          queryClient.invalidateQueries('creatives', 'processing');
        }
        if (isUserEvent) {
          showNotification({
            type: 'error',
            title: 'Error',
            description: 'An unexpected error occured. We brought you back to a safe place.',
            information: `Error: ${data.error}`,
          });
        }
      } else if (data.workerStatus === 'error' && !data.userCanceled) {
        queryClient.invalidateQueries('creatives', 'processing');
        if (isUserEvent) {
          showNotification({
            type: 'error',
            title: 'Render Error',
            description: data.workerError,
          });
        }
      } else if (data.task === 'generating-error') {
        queryClient.invalidateQueries('creatives', 'processing');
        if (isUserEvent) {
          if (data.error) {
            showNotification({
              type: 'error',
              title: 'Render Error',
              description: data.error,
            });
          }
          if (data.summary) {
            showCustomNotification({
              component: RENDERING_NOTIFICATION,
              payload: {
                type: 'error',
                title: 'Rendering error!',
                summary: data.summary,
                campaignType: data.campaignType,
              },
              options: {
                duration: Infinity,
              },
            });
          } else {
            showNotification({
              type: 'error',
              title: 'Rendering error',
              description: 'Rendering of your creatives has failed.',
              options: {
                duration: Infinity,
              },
            });
          }
        }
      } else if (data.task === 'generating-finished') {
        queryClient.invalidateQueries('creatives', 'processing');
        if (isUserEvent) {
          if (data.summary) {
            showCustomNotification({
              component: RENDERING_NOTIFICATION,
              payload: {
                type: 'success',
                title: 'Rendering finished!',
                summary: data.summary,
                campaignType: data.campaignType,
              },
              options: {
                duration: Infinity,
              },
            });
          } else {
            showNotification({
              type: 'success',
              title: 'Rendering finished',
              description: 'Rendering of your creatives has finished.',
              options: {
                duration: Infinity,
              },
            });
          }
        }
      } else if (data.task === 'generating-canceled') {
        queryClient.invalidateQueries('creatives', 'processing');
        if (isUserEvent) {
          if (data.summary) {
            showCustomNotification({
              component: RENDERING_NOTIFICATION,
              payload: {
                type: 'info',
                title: 'Rendering canceled!',
                summary: data.summary,
                campaignType: data.campaignType,
              },
              options: {
                duration: Infinity,
              },
            });
          } else {
            showNotification({
              type: 'success',
              title: 'Rendering canceled',
              description: 'Rendering of your creatives has been interrupted.',
              options: {
                duration: Infinity,
              },
            });
          }
        }
      } else if (data.task === 'publishing-finished') {
        if (isUserEvent) {
          showNotification({
            type: 'success',
            title: 'Publishing finished',
            description: 'Publishing of your creatives has finished.',
            options: {
              duration: Infinity,
            },
          });
        }
      } else if (data.task === 'publishing-error') {
        if (isUserEvent) {
          showNotification({
            type: 'error',
            title: 'Publishing error',
            description: 'Publishing of your creatives has failed.',
            information: `Error: ${data.message}`,
            options: {
              duration: Infinity,
            },
          });
        }
      }

      if (['generating-finished', 'generating-canceled', 'generating-failed', 'generating-error'].includes(data.task)) {
        queryClient.invalidateQueries('usage');
      }
    }
  }, [showNotification, showCustomNotification, queryClient, me]);

  useEvent('*', processEvent);

  return null;
}

NotificationManager.propTypes = {};

NotificationManager.defaultProps = {};

export default NotificationManager;
