import { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Dialog, Transition } from '@headlessui/react';
import { InformationCircleIcon, ExclamationIcon, CheckIcon } from '@heroicons/react/outline';

import Button from 'components/button';

function BasicDialog({ open, title, description, type, actions, closeLabel, onClose, onAction, showDismissButton, children }) {
  const cancelButtonRef = useRef(null);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-40 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={onClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white rounded-t-lg px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className={classNames(
                    'mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10',
                    {
                      'bg-indigo-100': type === 'primary',
                      'bg-red-100': type === 'danger',
                      'bg-green-100': type === 'success',
                    }
                  )}>
                    {type === 'primary' ? <InformationCircleIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" /> : null}
                    {type === 'danger' ? <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" /> : null}
                    {type === 'success' ? <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" /> : null}
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {description}
                      </p>
                    </div>
                    {children}
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 rounded-b-lg px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                {actions.map((action) => (
                  <Button
                    key={action.value}
                    label={action.label}
                    color={type}
                    className="w-full inline-flex justify-center sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => onAction(action.value)}
                  />
                ))}
                {showDismissButton && (
                  <Button
                    ref={cancelButtonRef}
                    label={closeLabel}
                    color="outlined"
                    className="w-full inline-flex justify-center sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={onClose}
                  />
                )}
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

BasicDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['primary', 'danger', 'success']),
  closeLabel: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  showDismissButton: PropTypes.bool,
  onAction: PropTypes.func,
  onClose: PropTypes.func,
};

BasicDialog.defaultProps = {
  open: false,
  title: '',
  description: '',
  type: 'primary',
  closeLabel: 'Cancel',
  actions: [{
    label: 'Confirm',
    value: 'confirm',
  }],
  showDismissButton: true,
  onAction: () => {},
  onClose: () => {},
};

export default BasicDialog;
