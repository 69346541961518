import { STORAGE_RANGES, STORAGE_COLORS, STORAGE_RANGE_STATUS_LIST } from 'constants/storage';

export function storageToRange(used, total) {
  const amount = used / total;
  const status = STORAGE_RANGE_STATUS_LIST[STORAGE_RANGES.findIndex((r) => amount >= r) || 0];

  return {
    status,
    color: STORAGE_COLORS[status],
  };
}
