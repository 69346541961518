import Button from 'components/button';

function ErrorFallback({error, resetErrorBoundary}) {
  return (
    <div role="alert" className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="max-w-max mx-auto">
        <main className="sm:flex">
          <p className="text-4xl font-extrabold text-indigo-600 sm:text-5xl">ERROR</p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Something went wrong</h1>
              <p className="mt-1 text-base text-gray-500">{error.message}</p>
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <Button label="Try Again" onClick={resetErrorBoundary} />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default ErrorFallback;
