import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { CheckCircleIcon, XCircleIcon, InformationCircleIcon, ExclamationIcon } from '@heroicons/react/outline';

function NotificationListItem({ title, description, information, type, ts }) {
  return (
    <div className="max-w-sm w-full bg-white pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
      <div className="p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0">
            {type === 'success' && <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />}
            {type === 'error' && <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />}
            {type === 'warning' && <ExclamationIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />}
            {type === 'info' && <InformationCircleIcon className="h-6 w-6 text-indigo-400" aria-hidden="true" />}
          </div>
          <div className="relative ml-3 w-0 flex-1 pt-0.5">
            <p className="text-sm font-medium text-gray-900">{title}</p>
            <p className="mt-1 text-sm text-gray-500">{description}</p>
            {information && (<p className="mt-1.5 text-xs text-gray-400">{information}</p>)}
            <p className="absolute top-0 right-0 mt-1 text-xs text-gray-400 text-right">{dayjs(ts).fromNow()}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

NotificationListItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  information: PropTypes.string,
  type: PropTypes.oneOf(['success', 'error', 'info']),
  ts: PropTypes.number,
};

NotificationListItem.defaultProps = {
  title: '',
  description: '',
  information: '',
  type: 'success',
  ts: dayjs().valueOf(),
};

export default NotificationListItem;
