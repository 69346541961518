import { useCallback } from 'react';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { FEATURES_ALL } from 'constants/features';
import * as usageService from 'services/api/usage';

function useUsage(mode = 'all') {
  const result = useQuery(
    ['usage', mode],
    () => {
      if (mode === 'current') {
        return usageService.fetchUsage({
          startDate: dayjs().startOf('month'),
          endDate: dayjs().endOf('month'),
        });
      }
      return usageService.fetchUsage();
    },
    {
      refetchInterval: 120000,
      select: useCallback((data) => {
        let total = 0;
        let rendered = 0;
        const amount = FEATURES_ALL.reduce((acc, feature) => {
          const stats = data.find((summary) => summary.type === `render-${feature}`);
          if (!stats) {
            acc[feature] = {
              total: 0,
              rendered: 0,
              succeeded: 0,
              failed: 0,
              canceled: 0,
            };
            return acc;
          }

          acc[feature] = {
            succeeded: parseInt(stats.usage.succeeded, 10),
            failed: parseInt(stats.usage.failed, 10),
            canceled: parseInt(stats.usage.canceled, 10),
          };
          acc[feature].total = acc[feature].succeeded + acc[feature].failed + acc[feature].canceled;
          acc[feature].rendered = acc[feature].succeeded + acc[feature].canceled;
          total += acc[feature].total;
          rendered += acc[feature].rendered;

          return acc;
        }, {});

        return {
          total,
          rendered,
          amount,
        };
      }, []),
    },
  );

  return result;
}

export default useUsage;
