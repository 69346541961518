import { Outlet } from 'react-router-dom';

import NotificationManager from 'features/notifications/manager';

function GraphLayout() {
  return (
    <div className="min-h-screen flex flex-col">
      <NotificationManager />
      <main className="flex grow flex-col">
        <Outlet/>
      </main>
    </div>
  );
}

GraphLayout.propTypes = {};

GraphLayout.defaultProps = {};

export default GraphLayout;
