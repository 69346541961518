import { Outlet } from 'react-router-dom';
import Versions from 'features/versioning/versions';

function AuthLayout() {
  return (
    <>
      <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <Outlet />
      </div>

      <div className="absolute bottom-0 right-0 p-2">
        <Versions />
      </div>
    </>
  );
}

export default AuthLayout;
