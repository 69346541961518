
export const STORAGE_RANGE_OK = 'storage-ok';
export const STORAGE_RANGE_WARNING = 'storage-warning';
export const STORAGE_RANGE_ERROR = 'storage-error';

export const STORAGE_RANGE_STATUS_LIST = [
  STORAGE_RANGE_ERROR,
  STORAGE_RANGE_WARNING,
  STORAGE_RANGE_OK,
];

export const STORAGE_RANGES = [
  1.0,
  0.8,
  0.0,
];

export const STORAGE_COLORS = {
  [STORAGE_RANGE_OK]: 'success',
  [STORAGE_RANGE_WARNING]: 'warning',
  [STORAGE_RANGE_ERROR]: 'danger',
};

export const STORAGE_CATEGORY_LABELS = {
  campaigns: 'Campaigns',
  templates: 'Templates',
  uploads: 'Other',
};

export const STORAGE_CATEGORY_COLORS = [
  'bg-indigo-500',
  'bg-pink-500',
  'bg-blue-500',
  'bg-purple-500',
  'bg-red-500',
  'bg-yellow-500',
];

export const STORAGE_TEXT_COLORS = [
  'text-indigo-500',
  'text-pink-500',
  'text-blue-500',
  'text-purple-500',
  'text-red-500',
  'text-yellow-500',
];