import axios from 'axios';

import { getBaseUrl } from 'utils/domain';

const instance = axios.create({
  baseURL: getBaseUrl(window.location.host),
});

instance.defaults.headers.common.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export function setAuthorizationHeader(token) {
  instance.defaults.headers.common.Authorization = `Bearer ${token}`;
}

export function setOrganisationHeader(organisationId) {
  if (organisationId) {
    instance.defaults.headers.common['organization-id'] = organisationId;
  }
}

export default instance;
