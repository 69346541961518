const hashStr = (str) => {
  return str.split('')
    .reduce((s, c) => Math.imul(31, s) + c.charCodeAt(0) | 0, 0)
    .toString(36);
};

const replacer = (_key, value) =>
  value instanceof Object && !(value instanceof Array) ?
    Object.keys(value)
    .sort()
    .reduce((sorted, key) => {
      sorted[key] = value[key];
      return sorted;
    }, {}) :
    value;

const hashObj = (obj) => {
  return hashStr(JSON.stringify(obj, replacer));
};

const hashArgs = (...args) => {
  return hashStr(JSON.stringify(args));
};

export {
  hashArgs,
  hashStr,
  hashObj,
};
