import { useCallback } from 'react';
import { useQuery } from 'react-query';
import * as organisationsService from 'services/api/organisations';

import { dateApiResponse } from 'utils/date';
import { mbToGb } from 'utils/math';

function useOrganisationDetails() {
  const result = useQuery(
    'organisation',
    () => organisationsService.fetchCurrentDetails(),
    {
      select: useCallback((data) => ({
        ...dateApiResponse(data, ['license_date', 'license_end_date']),
        available_storage: mbToGb(data.available_storage),
        total_storage: mbToGb(data.total_storage),
        storage_usage: Object.entries(data.storage_usage).reduce((acc, [key, value]) => ({
          ...acc,
          [key]: mbToGb(value),
        }), {}),
      }), []),
    },
  );

  return result;
}

export default useOrganisationDetails;
