import useBackendVersion from './use-backend-version';

import Dialog from 'components/dialog';

const frontend = {
  release: process.env.REACT_APP_VERSION,
};

function Versions() {
  const backend = useBackendVersion();

  function reload() {
    window.location.reload();
  }

  const showOutOfSync = process.env.NODE_ENV !== 'development' // don't show it for development
    && backend.isSuccess && backend.data.release !== frontend.release;

  return (
    <>
      <div className="text-xs font-light text-gray-400">
        <span>FE {frontend.release}</span>
        {backend.isSuccess && (
          <span> | BE {backend.data.release}</span>
        )}
      </div>
      <Dialog
        open={showOutOfSync}
        title="Update Sunfish"
        description="Sunfish just got better! This page needs a reload to continue working."
        actions={[{ value: 'reload', label: 'Reload Sunfish' }]}
        onAction={reload}
        showDismissButton={false}
      />
    </>
  );
}

export default Versions;
