import { Outlet } from 'react-router-dom';

import NavigationBar from 'features/navigation/bar';
import NavigationHeader from 'features/navigation/header';
import NotificationManager from 'features/notifications/manager';
import Versions from 'features/versioning/versions';

function AppLayout() {
  return (
    <>
      <div className="min-h-full">
        <NavigationBar />
        <NavigationHeader />
        <NotificationManager />
        <main>
          <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
            <div className="px-4 py-6 sm:px-0">
              <Outlet/>
            </div>
          </div>
        </main>
      </div>
      <div className="absolute bottom-0 right-0 py-2 px-6 lg:px-8 xl:px-2">
        <Versions />
      </div>
    </>
  );
}

AppLayout.propTypes = {};

AppLayout.defaultProps = {};

export default AppLayout;
