import client from './client';

export async function fetchTemplates() {
  const result = await client.get('/templates', {
    params: {
      perPage: 1000,
    },
  });
  return result.data;
}

export async function fetchTemplate(templateId) {
  if (templateId) {
    const result = await client.get(`/templates/${templateId}`);
    return result.data;
  }
  return {};
}

export async function createBannerTemplate(data) {
  const formData = new FormData();
  formData.append('file', data.file);

  const result = await client.post('/templates/banner', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });

  return result.data;
}

export async function changeStatus(templateId, status) {
  const result = await client.put(`/templates/status/${templateId}`, null, {
    params: {
      status,
    },
  });
  return result.data;
}

export async function createBannerTemplateVersion(templateId, data) {
  const formData = new FormData();
  formData.append('file', data.file);

  const result = await client.put(`/templates/banner/${templateId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });

  return result.data;
}

export async function deleteTemplate(templateId) {
  const result = await client.delete(`/templates/${templateId}`);
  return result.data;
}

export async function restoreTemplate(templateId) {
  const result = await client.put(`/templates/undelete/${templateId}`);
  return result.data;
}

export async function purgeTemplate(templateId, versionId = null) {
  const result = await client.delete(`/templates/${templateId}/unused-versions/${versionId || ''}`);
  return result.data;
}
