import { useCallback } from 'react';
import { useQuery } from 'react-query';
import * as systemNotificationService from 'services/api/system-notifications';

import { dateApiResponse } from 'utils/date';

function useAnnouncements() {
  const result = useQuery(
    ['announcements'],
    () => systemNotificationService.fetchOpenSystemNotifications(),
    {
      select: useCallback((data) => data.map((data) => dateApiResponse(data)), []),
    }
  );
  return result;
}

export default useAnnouncements;
