const createNotificationsSlice = (set, get) => ({
  notifications: [],
  unread: false,
  addNotification: (notification) => set({
    notifications: [
      notification,
      ...get().notifications,
    ],
    unread: true,
  }),
  confirmReadNotification: () => set({
    unread: false,
  }),
  clearNotifications: () => set({ notifications: [], unread: false }),
});

export default createNotificationsSlice;
