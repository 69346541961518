import client from './client';

import { getSubdomain } from 'utils/domain';

export async function getCurrent() {
  try {
    const result = await client.get('/');
    return result.data;
  } catch (error) {
    if ((!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
      && getSubdomain(window.location.host) !== 'superadmin') {
      return {
        id: process.env.REACT_APP_DEV_ORGANISATION_ID || 'no-organisation-id-set',
        name: process.env.REACT_APP_DEV_ORGANISATION_NAME || 'no-organisation-name-set',
      };
    }
  }
  throw Error('Could not get current organization');
}

export async function fetchCurrentDetails() {
  const result = await client.get('/organization');
  return result.data;
}

function updateOrganisationPayload(data) {
  return {
    name: data.name,
    details: {
      vat: data.details.vat,
      address: {
        street: data.details.address.street,
        zip: data.details.address.zip,
        city: data.details.address.city,
        country: data.details.address.country,
        region: data.details.address.region,
      },
    },
    license_date: data.license_date?.toISOString(),
    features: {
      permissions: data.features.permissions,
    },
  };
}

export async function updateCurrent(data) {
  const result = await client.post('/organization', updateOrganisationPayload(data));

  return result.data;
}

export async function fetchOrganisations() {
  const result = await client.get('/organizations?perPage=1000');
  return result.data;
}

export async function createOrganisation(data) {
  const result = await client.post('/organizations', {
    user: {
      email: data.user.email,
      details: {
        first_name: data.user.details.first_name,
        last_name: data.user.details.last_name,
      },
    },
    organization: {
      name: data.organization.name,
      details: {
        vat: data.organization.details.vat,
        address: {
          street: data.organization.details.address.street,
          zip: data.organization.details.address.zip,
          city: data.organization.details.address.city,
          country: data.organization.details.address.country,
          region: data.organization.details.address.region,
        },
      },
      features: {
        permissions: data.features.permissions,
      },
      license_date: data.organization.license_date.toISOString(),
    },
  });
  return result.data;
}

export async function requestOwnershipChange(newOwnerId) {
  const result = await client.put(`/owners/${newOwnerId}`);

  return result.data;
}

export async function confirmOwnershipChange(userId, signature) {
  if (userId) {
    const result = await client.get(`/owners/${userId}`, {
      params: {
        signature,
      },
    });
    return result;
  }
  return {};
}

export async function uploadOrganisationPicture(data) {
  const formData = new FormData();
  formData.append('file', data);

  const result = await client.post('/organization/image', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });

  return result.data;
}

export async function deleteOrganisationPicture() {
  const result = await client.delete('/organization/image');

  return result.data;
}

export async function extendLicense(license_date) {
  const result = await client.post('/organization', {
    license_date: license_date?.toISOString(),
  });

  return result.data;
}