import dayjs from 'dayjs';
import { useRef, useEffect } from 'react';

function useTriggerTimestamp(fn, timestamp) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = fn;
  });

  useEffect(() => {
    if (timestamp !== null) {
      const delay = timestamp - dayjs().valueOf();
      const id = setTimeout(() => savedCallback.current(), delay);
      return () => clearTimeout(id);
    }
  }, [timestamp]);
}

export default useTriggerTimestamp;
