export const FEATURE_BANNER = 'banner';
export const FEATURE_VIDEO = 'video';
export const FEATURE_IMAGE = 'image';

export const FEATURE_LABELS = {
  [FEATURE_BANNER]: 'Banner',
  [FEATURE_VIDEO]: 'Video',
  [FEATURE_IMAGE]: 'Image',
};

export const FEATURES_ALL = [
  FEATURE_BANNER,
  FEATURE_VIDEO,
  FEATURE_IMAGE,
];

export const FEATURE_COLORS = {
  [FEATURE_BANNER]: 'indigo',
  [FEATURE_VIDEO]: 'pink',
  [FEATURE_IMAGE]: 'purple',
};
