import { useQuery } from 'react-query';
import * as organizationsService from 'services/api/organisations';

import { storageToRange } from 'utils/storage';
import { mbToGb } from 'utils/math';

function useStorage() {
  const result = useQuery(
    'storage',
    () => organizationsService.fetchCurrentDetails(),
    {
      refetchInterval: 120000,
    },
  );

  if (result.isSuccess) {
    const consumedStorage = result.data.total_storage - result.data.available_storage;
    const { color } = storageToRange(consumedStorage, result.data.total_storage);

    return {
      consumed: mbToGb(consumedStorage),
      total: mbToGb(result.data.total_storage),
      color,
    };
  }

  return null;
}

export default useStorage;
