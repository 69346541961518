import { useCallback } from 'react';
import { useQuery } from 'react-query';
import * as spacesService from 'services/api/spaces';

import { dateApiResponse } from 'utils/date';

function useSpace(spaceId) {
  const result = useQuery(
    ['space', spaceId],
    () => spacesService.fetchSpace(spaceId),
    {
      enabled: !!spaceId,
      select: useCallback((data) => dateApiResponse(data), []),
    },
  );

  return result;
}

export default useSpace;
