import useAuth from 'hooks/use-auth';
import useUserDetails from 'hooks/use-user-details';

const DEFAULT_PROFILE = {
  id: 0,
  details: {
    first_name: "John",
    last_name: "Doe"
  },
  email: 'me@adon.io',
  imageUrl: null,
};

function useMe() {
  const auth = useAuth();
  const localUser = useUserDetails(auth.isLoggedIn ? 'current' : null);
  return localUser.isSuccess ? localUser.data : DEFAULT_PROFILE;
}

export default useMe;
