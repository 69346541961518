import { useCallback } from 'react';
import { useQuery } from 'react-query';
import * as organisationsService from 'services/api/organisations';

function useOrganisationFeatures() {
  const result = useQuery(
    'features',
    () => {
      if (window.location.hostname.startsWith('superadmin')) {
        // used on multiple occasitions for shared content but super admin view should not fetch organisation specifics
        return { features: { permissions: [] } };
      }
      return organisationsService.fetchCurrentDetails();
    },
    {
      select: useCallback((data) => data.features.permissions, []),
    },
  );

  return result;
}

export default useOrganisationFeatures;
