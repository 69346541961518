import client from './client';

export async function login(email, password) {
  const result = await client.post('/auth', {
    email,
    password,
  });
  return result;
}

export async function logout(userId) {
  try {
    const result = await client.delete(`/auth/${userId}`);
    return result.data;
  } catch (error) {
    if (error.response.status === 401) {
      // ignore 401 errors; they mean that the user was already logged out by the server
      // (e.g. because the user logged out in a second open browser tab)
      return;
    }

    throw error;
  }
}

export async function requestResetPassword(email) {
  const result = await client.post('/password/reset', {
    email,
  }, {
    headers: {
      Authorization: null,
    },
  });
  return result.data;
}

export async function verifyUser(userId, password, signature) {
  if (userId) {
    const result = await client.put(`/users/password/${userId}`, {
      password,
    }, {
      params: {
        signature,
      },
      headers: {
        Authorization: null,
      },
    });
    return result;
  }
  return {};
}

export async function generateAuthSignature() {
  const result = await client.get('/auth/seamless');
  return result.data;
}

export async function resume(userId, signature) {
  const result = await client.put(`/auth/seamless/${userId}`, {}, {
    params: {
      signature,
    },
  });
  return result;
}
