import dayjs from 'dayjs';

export function dateApiResponse(data, additionalProperties = []) {
  return {
    ...data,
    created_at: data.created_at ? dayjs(data.created_at) : null,
    updated_at: data.updated_at ? dayjs(data.updated_at) : null,
    deleted_at: data.deleted_at ? dayjs(data.deleted_at) : null,
    ...additionalProperties.reduce((acc, p) => ({
      ...acc,
      [p]: data[p] ? dayjs(data[p]) : null,
    }), {}),
  };
}

/**
 * returns a full date string in our specified format
 *
 * @param {object} date optional day.js instance, defaults to now
 * @returns {string}
 */
export function fullDateString(date = dayjs()) {
  return date.format('YYYYMMDD-HHmmss');
}
