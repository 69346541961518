import { Navigate } from 'react-router-dom';


import useAuth from 'hooks/use-auth';

function StartPage() {
  const auth = useAuth();

  if (auth.useSuperAdmin) {
    return (<Navigate to="/organizations" />);
  }

  return (<Navigate to="/campaigns" />);
}

export default StartPage;