import useProvideAuth from './use-provide-auth';
import { authContext } from './auth-context';

function ProvideAuth({ children }) {
  const auth = useProvideAuth();

  if (!auth.isInitilized) {
    return null;
  }

  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export default ProvideAuth;
