import classNames from 'classnames';
import { Popover } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';

import Button from 'components/button';
import CustomButton from 'components/custom-button';

import useUsage from 'hooks/use-usage';
import useStorage from 'hooks/use-storage';

import { FEATURE_LABELS } from 'constants/features';

import { toDecimal } from 'utils/math';
import { pl } from 'utils/text';

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';

function Usage({ className }) {
  const navigate = useNavigate();
  const usage = useUsage('current');
  const storage = useStorage();

  if (!usage.isSuccess || !storage) {
    return null;
  }

  return (
    <Popover className="relative">
      <Popover.Button className={classNames(
        'border-gray-300 border text-gray-300 hover:bg-gray-700 hover:text-white focus:outline-none px-4 py-2 sm:text-sm font-medium rounded-md group inline-flex items-between',
        className,
      )}>
        <span>{(new Date()).toLocaleString('default', { month: 'long' })} Usage</span>
        <ChevronDownIcon
          className="text-gray-300 group-hover:text-white ml-2 h-5 w-5 -mr-1"
          aria-hidden="true"
        />
      </Popover.Button>

      <Popover.Panel className="absolute z-10 top-0 right-0 bg-white rounded-md w-52 shadow-lg p-4 pt-2.5 flex flex-col text-sm">
        {({ close }) => (
          <>
            <div className="flex justify-between items-center text-gray-900 font-medium">
              <span>{(new Date()).toLocaleString('default', { month: 'long' })} Usage</span>
              <CustomButton
                srLabel="Close"
                className="ml-2 -mr-1 rounded-md"
                onClick={close}
              >
                <ChevronUpIcon
                  className="text-gray-900 h-5 w-5"
                  aria-hidden="true"
                />
              </CustomButton>
            </div>
            <div className="py-2 text-gray-500 space-y-1">
              {Object.keys(usage.data.amount).map((feature) => (
                <div
                  key={feature}
                  className="flex justify-between"
                >
                  <span>{pl(usage.data.amount[feature].rendered, FEATURE_LABELS[feature], { hideValue: true })}:</span>
                  <span>{usage.data.amount[feature].rendered}</span>
                </div>
              ))}
            </div>
            <Button
              label="Detailed Statistics"
              size="sm"
              className="my-1 self-center"
              onClick={() => navigate('/statistics?m=month')}
            />
            <div className="text-gray-900 font-medium border-t border-gray-200 mt-2 pt-2">
              Storage status
            </div>
            <div className="text-gray-500">
              {toDecimal(storage.consumed, 1)}GB used of {toDecimal(storage.total, 1)}GB
            </div>
          </>
        )}
      </Popover.Panel>
    </Popover>
  );
}

export default Usage;
