import { useEffect } from 'react';
import EventHub from 'services/events';

function useEvent(event, callback) {
  useEffect(() => {
    let evt;
    if (event) {
      if (event === '*') {
        evt = EventHub.subscribeAll(callback);
      } else {
        evt = EventHub.subscribe(event, callback);
      }
      return () => evt.unsubscribe();
    }
  }, [event, callback]);
}

export default useEvent;
