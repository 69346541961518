import { useCallback } from 'react';
import { useQuery } from 'react-query';
import * as platformsService from 'services/api/platforms';

import { dateApiResponse } from 'utils/date';

function usePlatform(platformId) {
  const result = useQuery(
    ['platform', platformId],
    () => platformsService.fetchPlatform(platformId),
    {
      enabled: !!platformId,
      select: useCallback((data) => {
        const sortedData = [
          ...data.map((data) => dateApiResponse(data)),
        ].sort((a, b) => b.version - a.version);
        return {
          current: sortedData[0], // sortedData.pop(),
          versions: sortedData,
        };
      }, []),
    },
  );

  return result;
}

export default usePlatform;
