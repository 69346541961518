
import { useContext } from 'react';

import { authContext } from 'features/auth/auth-context';

function useAuth() {
  return useContext(authContext);
}

export default useAuth;
