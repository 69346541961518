import { useQuery } from 'react-query';
import * as versionService from 'services/api/versioning';

function useBackendVersion() {
  const result = useQuery(
    ['backend-version'],
    () => versionService.fetchVersion(),
  );

  return result;
}

export default useBackendVersion;
