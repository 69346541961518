import client from './client';

export async function fetchUsage({ startDate = null, endDate = null } = {}) {
  const result = await client.get('/usage', {
    params: {
      sumOnly: true,
      startDate: startDate?.toISOString(),
      endDate: endDate?.toISOString(),
    },
  });
  return result.data;
}
