import client from './client';

export async function fetchSpaces() {
  const result = await client.get('/spaces', {
    params: {
      perPage: 1000,
    },
  });
  return result.data;
}

export async function fetchSpace(spaceId) {
  if (spaceId) {
    const result = await client.get(`/spaces/${spaceId}`);
    return result.data;
  }
  return {};
}

export async function addUser(spaceId, userId) {
  const result = await client.put(`/spaces/${spaceId}/users/${userId}`);
  return result.data;
}

export async function removeUser(spaceId, userId) {
  const result = await client.delete(`/spaces/${spaceId}/users/${userId}`);
  return result.data;
}

export async function createSpace(data, users) {
  const result = await client.post('/spaces', {
    name: data.name,
    description: data.description,
  });

  try {
    await Promise.all(users.map((user) => addUser(result.data.id, user)));
  } catch (error) {
    // could not assign user to space, failure will be tolerated since its not critical
    console.log(error);
  }

  return result.data;
}

export async function updateSpace(spaceId, data) {
  const result = await client.put(`/spaces/${spaceId}`, {
    name: data.name,
    description: data.description,
  });

  return result.data;
}

export async function deleteSpace(spaceId, moveToSpaceId) {
  const result = await client.delete(`/spaces/${spaceId}`, {
    params: moveToSpaceId ? {
      move_campaigns_to_space: moveToSpaceId,
    } : {
      delete_campaigns: true,
    },
  });
  return result.data;
}
