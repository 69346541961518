import { useCallback } from 'react';
import { useQuery } from 'react-query';
import * as campaignsService from 'services/api/campaigns';

import { dateApiResponse } from 'utils/date';

function useCampaign(campaignId) {
  const result = useQuery(
    ['campaign', campaignId],
    () => campaignsService.fetchCampaign(campaignId),
    {
      enabled: !!campaignId,
      select: useCallback((data) => dateApiResponse(data), []),
    },
  );

  return result;
}

export default useCampaign;
