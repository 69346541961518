export function toPercent(value, digits = 2) {
  return Math.round(value * Math.pow(10, digits + 2)) / 100;
}

export function toDecimal(value, digits = 2) {
  return (Math.round(value * Math.pow(10, digits)) / Math.pow(10, digits)).toFixed(digits);
}

export function mbToGb(value) {
  return value / 1000;
}

export function gbToMb(value) {
  return value * 1000;
}